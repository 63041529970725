<template>
  <div>
    <label>{{ label }}</label>
    <div :class="mainClass">
      <select
        style="width: 100vw"
        :class="errors.length ? 'is-danger' : ''"
        :id="id"
        ref="input"
        v-model="selected"
        v-bind="$attrs"
      >
        <slot />
      </select>
      <div
        v-if="errors.length"
        :class="errors.length ? 'invalid-feedback' : ''"
      >
        <strong class="has-text-danger" style="font-size: 12px">{{
          errors[0]
        }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `select-input-${this._uid}`;
      },
    },
    mainClass: {
      type: String,
      default: "",
    },
    value: [String, Number, Boolean],
    label: String,
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    selected(selected) {
      this.$emit("input", selected);
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style scoped>
</style>