<template>
  <div class="box-content">
    <mcp-modal :show="show" @closeModal="showAddCustomer">
      <add-customer
        @closeModal="showAddCustomer"
        :callBackFunction="refreshCustomer"
      />
    </mcp-modal>
    <mcp-modal :show="showEdit" @closeModal="showAlterCustomer">
      <alter-customer
        :customer="customer"
        @closeModal="showAlterCustomer"
        :callBackFunction="refreshCustomer"
      />
    </mcp-modal>
    <ul class="tab-heading">
      <li
        @click.prevent="activeTab(header, key)"
        :class="key === index ? 'active-head' : ''"
        v-for="(header, key) in headers"
        :key="key"
      >
        <a href="#">{{ header.name }}</a>
      </li>
    </ul>
    <search :makeSearch="makeSearch">
      <template #buttons>
        <button
          v-if="index === 0"
          @click="showAddCustomer"
          class="button is-outlined is-small"
        >
          Ajouter un client
        </button>
        <button @click="refreshCustomer" class="button is-outlined is-small">
          Actualiser
        </button>
      </template>
    </search>
    <data-table
      :columns="index === 0 ? columns : columnsNot"
      :loading="loading"
      :counter="customers.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body v-if="index === 1">
        <tbody>
          <tr v-for="(customer, key) in customers" :key="key">
            <td>{{ customer.lastname }}</td>
            <td>{{ customer.firstname }}</td>
            <td>{{ customer.phone }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ customer.created_at }}</td>
          </tr>
        </tbody>
      </template>
      <template #body v-else>
        <tbody>
          <tr v-for="(customer, key) in customers" :key="key">
            <td>{{ customer.lastname }}</td>
            <td>{{ customer.firstname }}</td>
            <td>{{ customer.phone }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ customer.token }}</td>
            <td>{{ customer.expirydate }}</td>
            <td>
              <div class="d-flex">
                <mcp-tooltip title="Modifier">
                  <i @click="editCustomer(customer)" class="fa fa-edit"></i>
                </mcp-tooltip>
                <mcp-tooltip title="Supprimer">
                  <i @click="deleteCustomer(customer)" class="fa fa-remove"></i>
                </mcp-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>

<script>
import dataTable from "@/components/table/datatable";
import search from "@/components/table/search";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import mcpModal from "@/components/McpModal";
import addCustomer from "./addCustomer.vue";
import alterCustomer from "./alterCustomer.vue";
export default {
  name: "customers",
  components: {
    dataTable,
    search,
    addCustomer,
    mcpModal,
    alterCustomer,
  },
  data: (vm) => ({
    loading: false,
    index: 0,
    show: false,
    showEdit: false,
    customer: {},
    columnsNot: [
      { name: "lastname", label: "Nom" },
      { name: "firstname", label: "Prénom" },
      { name: "phone", label: "Téléphone" },
      { name: "email", label: "Email" },
      { name: "created_at", label: "Date et heure" },
    ],
    columns: [
      { name: "lastname", label: "Nom" },
      { name: "firstname", label: "Prénom" },
      { name: "phone", label: "Téléphone" },
      { name: "email", label: "Email" },
      { name: "token", label: "SpKey" },
      { name: "created_at", label: "Date d'expiration" },
      { name: "action", label: "Actions" },
    ],
    headers: [
      { name: "Clients validés", url: "customers", page: 1 },
      {
        name: "Clients en attente de validation",
        url: "customers/not-validate",
        page: 1,
      },
    ],
    current: { name: "Clients validés", url: "customers", page: 1 },
  }),

  computed: {
    ...mapGetters({
      customers: `${TYPES.modules.CUSTOMERS}/${TYPES.getters.GET_CUSTOMERS}`,
      meta: `${TYPES.modules.CUSTOMERS}/${TYPES.getters.GET_CUSTOMERS_META}`,
    }),
  },

  created() {
    this.$store.commit(
      `${TYPES.modules.CUSTOMERS}/${TYPES.mutations.REST_CUSTOMER_DATA}`
    );
  },

  mounted() {
    this.getCustomers();
  },

  methods: {
    activeTab(model, index) {
      if (index != this.index) {
        this.search = "";
        this.index = index;
        this.current = model;
        this.getCustomers();
      }
    },

    navigationFunction(page) {
      this.current.page = page;
      this.getCustomers();
    },

    getCustomers() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.CUSTOMERS + "/" + TYPES.actions.GET_CUSTOMERS}`,
          { url: this.current.url, data: { page: this.current.page } }
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch(search) {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.CUSTOMERS + "/" + TYPES.actions.GET_CUSTOMERS}`,
          {
            url: this.current.url,
            data: { page: this.current.page, search: search },
          }
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshCustomer() {
      this.loading = true;
      this.current.page = 1;
      this.$store
        .dispatch(
          `${TYPES.modules.CUSTOMERS + "/" + TYPES.actions.GET_CUSTOMERS}`,
          { url: this.current.url, data: { page: this.current.page } }
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    showAddCustomer() {
      this.show = !this.show;
    },

    showAlterCustomer() {
      this.showEdit = !this.showEdit;
    },

    editCustomer(customer) {
      this.customer = { ...customer };
      this.showAlterCustomer();
    },

    deleteCustomer(customer) {
      if (confirm("voulez vous supprimer ce client ?")) {
        this.loading = true;
        this.$store
          .dispatch(
            `${
              TYPES.modules.CUSTOMERS + "/" + TYPES.actions.REMOVE_MCP_CUSTOMER
            }`,
            customer
          )
          .then((res) => {
            this.loading = false;
            if (res.status === true) {
              this.$toast.success("Opération effectuée.");
            } else
              this.$toast.error("Une erreur est survenue lors de l'opération.");
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>