<template>
  <div>
    <h2>Ajouter un client</h2>
    <div class="pt-10">
      <text-input
        :errors="errors.lastname"
        v-model="form.lastname"
        label="Nom du client"
        type="text"
      />
    </div>
    <div class="pt-10">
      <text-input
        :errors="errors.firstname"
        v-model="form.firstname"
        label="Prénom"
        type="text"
      />
    </div>
    <div class="pt-10">
      <text-input
        :errors="errors.email"
        v-model="form.email"
        label="Adresse email"
        type="email"
      />
    </div>
    <div class="pt-10">
      <text-input
        :errors="errors.phone"
        v-model="form.phone"
        label="Téléphone"
        type="email"
      />
    </div>
    <div class="pt-10">
      <text-input
        :errors="errors.ifu"
        v-model="form.ifu"
        label="Ifu"
        type="number"
      />
    </div>

    <div class="pt-10">
      <select-input
        mainClass="select"
        v-model="form.status"
        label="Voulez-vous activer le compte ?"
      >
        <option value="1">Oui</option>
        <option value="0">Non</option>
      </select-input>
    </div>
    <hr />
    <div class="pt-10 d-flex-right">
      <button
        @click="saveCustomer"
        :disabled="loading"
        :class="
          loading
            ? 'button is-responsive is-loading is-primary'
            : 'button is-responsive is-primary'
        "
      >
        Enregistrer
      </button>
      <button class="button is-outlined is-responsive" @click="cancelOperation">
        Annuler
      </button>
    </div>
  </div>
</template>

<script>
const Default_from = {
  lastname: "",
  firstname: "",
  email: "",
  phone: "",
  ifu: "",
  status: "1",
};
import TextInput from "@/components/TextInput";
import SelectInput from "@/components/SelectInput";
import { TYPES } from "@/store/types";
export default {
  name: "addCustomer",
  components: { TextInput, SelectInput },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    callBackFunction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    errors: [],
    loading: false,
    form: {
      lastname: "",
      firstname: "",
      email: "",
      phone: "",
      ifu: "",
      status: "1",
    },
  }),
  methods: {
    cancelOperation() {
      this.errors = [];
      this.form = { ...Default_from };
      this.$emit("closeModal");
    },
    saveCustomer() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.CUSTOMERS + "/" + TYPES.actions.ADD_CUSTOMERS}`,
          this.form
        )
        .then((res) => {
          this.loading = false;
          this.$toast.success(`Opération effectuée`);
          this.$emit("closeModal");
          this.callBackFunction();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.mcp-modal {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 400px;
  z-index: 10;
  background-color: #fff;
}

.mcp-modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #646161da;
  z-index: 5;
}
.mcp-modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}
.mcp-modal-content {
  width: 400px;
}
.mcp-box {
  background-color: #fff;
  border-radius: 6px;
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
}
input[type="checkbox"] {
  display: inline-block !important;
}
</style>